import React from 'react'
import './AboutUsCompany.css'

export default function AboutUsCompany({data}) {
    return (
        <section className='aboutUs-company___container'>
            
        <div className='aboutUs-company___content' >
            <h2 className='aboutUs-company__headline' >
            {data.title}
            </h2>
            <p className='aboutUs-company__text' >
            {data.text}
            </p>
        </div>

        <div className='aboutUs-company___content' >
            <h2 className='aboutUs-company__headline' >
            {data.title1}
            </h2>
            <p className='aboutUs-company__text' >
            {data.paragraphOne}
            </p>
            <p className='aboutUs-company__text' >
            {data.paragraphTwo}
            </p>
        </div>

        <div className='aboutUs-company___content' >
            <h2 className='aboutUs-company__headline' >
            {data.title2}
            </h2>
            <p className='aboutUs-company__text' >
            {data.paragraphThree}
            </p>
            <p className='aboutUs-company__text' >
            {data.paragraphFour}
            </p>
        </div>

        </section>
    )
}
