import React, { useEffect } from "react";
import AboutUsAboutSection from "../../components/AboutUsComponents/AboutUs/AboutUsAboutSection";
import AboutUsCompany from "../../components/AboutUsComponents/Company/AboutUsCompany";
import AboutUsTeamSection from "../../components/AboutUsComponents/Team/AboutUsTeamSection";
import Hero from "../../components/Common/Hero/Hero";
import heroImg from "./zdj_about_us.jpg";
import eea from "./EEA_logo.png";

export default function AboutUsPage({data}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero img={heroImg} headline={eea}/>
      <AboutUsAboutSection data={data.about} />
      {/* <AboutUsTeamSection data={data.team}/> */}
      <AboutUsCompany data={data.company}/>
    </div>
  );
}
