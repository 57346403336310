import React, { useEffect } from "react";
import Hero from "../../components/Common/Hero/Hero";
import EurekaAboutSection from "../../components/EurekaComponents/EurekaAboutSection/EurekaAboutSection";
import EurekaFeaturesSection from "../../components/EurekaComponents/EurekaFeaturesSection/EurekaFeaturesSection";
import EurekaSloganSection from "../../components/EurekaComponents/SloganSection/EurekaSloganSection";
import eurekaHero from "./zdj_eureka.jpg";
import polyglot from "./logo_polyglot.png";

export default function EurekaPage({data}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero img={eurekaHero} headline={polyglot} />
      <EurekaSloganSection data={data.slogan}/>
      <EurekaAboutSection data={data.about}/>
      <EurekaFeaturesSection data={data.features}/>
    </div>
  );
}
