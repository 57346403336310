import React from "react";
import "./Hero.css";


export default function Hero({ img, headline , }) {
  return (
    <>
      <div
        className="hero__img-container"
        style={{ backgroundImage: `url(${img})` }}
      ></div>
      <h1 className="hero__headline">
        <div className="hero__img-wrapper">
          <img className="image" alt="polyglot-logo" src={headline} />
        </div>
      </h1>
    </>
  );
}
