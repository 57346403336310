import "./Navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";

import { useContext, useEffect, useState } from "react";

import logo from "./EEA_logo.png";
import { HashLink } from "react-router-hash-link";
import { LanguageContext } from "../../../contexts/LanguageContext";

const DropDown = ({ pathName, data }) => {
  const { lang, setLang } = useContext(LanguageContext);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.getElementById("App").addEventListener("click", (e) => {
      const navBtn = document.getElementById("our_products");
      if (navBtn.isEqualNode(e.target)) return;
      setIsOpen(false);
    });
  }, [lang]);

  return (
    <li className="navbar__list-element navbar-dropdown">
      <a
        onClick={() => setIsOpen((s) => !s)}
        className="navbar__link"
        id="our_products"
      >
        {data[0]}
      </a>
      <ul
        style={{ display: isOpen ? "inline-block" : "none" }}
        className="navbar-dropdown__list"
      >
        <NavbarLink currentPath={pathName} linkURL={`/polyglot`}>
          {data[1]}
        </NavbarLink>
        {/* <NavbarLink currentPath={pathName} linkURL={`/${lang}/other-projects`}>
          {data[2]}
        </NavbarLink> */}
      </ul>
    </li>
  );
};

function NavbarLink(props) {
  return (
    <li
      className={
        "navbar__list-element" +
        (props.currentPath === props.linkURL
          ? " navbar__list-element--active"
          : "")
      }
    >
      <Link to={props.linkURL} className="navbar__link">
        {props.children}
      </Link>
    </li>
  );
}

function HashNavbarLink(props) {
  return (
    <li
      className={
        "navbar__list-element" +
        (props.currentPath === props.linkURL
          ? " navbar__list-element--active"
          : "")
      }
    >
      <HashLink smooth to={props.linkURL} className="navbar__link">
        {props.children}
      </HashLink>
    </li>
  );
}

function Navbar({ data }) {
  const pathName = useLocation().pathname;
  // const { lang, setLang } = useContext(LanguageContext);
  // const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // useEffect(() => {
  //   document.getElementById("App").addEventListener("click",(e)=>{
  //     const navBtn = document.getElementById("our_products");
  //     if(!navBtn.isEqualNode(e.target)){

  //     }
  //   });
  // }, [])

  // const changeLanguage = (newLang) => {
  //   setLang(newLang);
  //   if (pathName.length > 3) {
  //     navigate(`/${newLang}/${pathName.substring(4, pathName.length)}`);
  //   } else navigate(`/${newLang}`);
  // };

  return (
    <header className={"navbar"}>
      <div className="navbar__main-wrapper">
          <Link to="/"  > <img src={logo} className="navbar__logo"/></Link>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="navbar__hamburger"
        >
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
        </button>
      </div>
      <nav
        className={"navbar__menu" + (isMenuOpen ? " navbar__menu--active" : "")}
      >
        <ul className="navbar__list">
          <NavbarLink currentPath={pathName} linkURL={`/about-us`}>
            {data.links[0]}
          </NavbarLink>
          <DropDown pathName={pathName} data={data.aboutUs} />
          {/* <NavbarLink currentPath={pathName} linkURL={`/${lang}/career`}>
            {data.links[1]}
          </NavbarLink> */}
          <HashNavbarLink currentPath={pathName} linkURL={`/#contact`}>
            {data.links[2]}
          </HashNavbarLink>
          {/* <div style={{ color: "white", cursor: "pointer" }}>
            {lang === "pl" ? (
              <div
                onClick={() => changeLanguage("en")}
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={data.languages.link}
                  alt={data.languages.lang}
                  style={{ width: "35px", height: "auto" }}
                ></img>
              </div>
            ) : (
              <div
                onClick={() => changeLanguage("pl")}
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={data.languages.link}
                  alt={data.languages.lang}
                  style={{ width: "35px", height: "auto" }}
                ></img>
              </div>
            )}
          </div> */}
        </ul>
      </nav>
    </header>
  );
}

export default Navbar;
