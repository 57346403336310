
import polishFlag from "../data/languages/polski_o.png"
import englishFlag from "../data/languages/angielski.png"

export const english = {
    navbar: {
      links: ["About us",  "polyglot", "Contact"],
      aboutUs: ["Our Products","polyglot", "Other Projects"],
      languages: {link: polishFlag, lang: "Polski"}
    },
    homePage: {
      heroSection: {
        title: "The highest quality language education",
        subtitle: "See more",
      },
      aboutSection: {
        tittle: "About us",
        subtitle: `The aim of our products is to make studying a language possible at any time, place and with no time limits. Our goal and mission is not only to respond to users' needs resulting from an increasingly dynamic lifestyle, but above all to promote the idea of lifelong language learning. We will invariably pursue this goal by providing tools for effective learning, thanks to which the adventure with a foreign language or another field becomes a delight.`,
        catchWords: `Everyone in the world deserves access to quality language education.`,
      },
      offerSection: {
        title: "Why is it important for you to learn languages?",
        paragrafOne: "Increase cultural awareness",
        paragrafTwo: "Become a global citizen",
        paragrafThree: "Get smarter",
        paragrafFour: "Live better, live longer",
        text: `Foreign language study creates more positive attitudes and less prejudice toward people who are different.`,
      },
      contactSection: {
        title: "Contact us",
        name: "Name",
        lastName: "Last name",
        email: "e-mail adress",
        message: "Message content",
        submit: "Send",
      },
    },
    eureka: {
      hero: {
        headline: "POLYGLOT",
      },
      slogan: {
        text: `Learning a new language is transformative. It can help you get a better job, a better degree, meet new people and live life changing experiences.`,
      },
      about: {
        paragraphOneTitle: `LANGUAGE LEARNING`,
        paragraphOneText: `It has been proven that language immersion and communication with native speakers is the most effective way to learn a language. Taking language classes may be useful, but the true test is conversing with natives, and because not everyone has the luxury to travel or be immersed in a foreign language, polyglot brings this most authentic learning experience to you.`,
        paragraphTwoTitle: `TRAVEL`,
        paragraphTwoText: `No money? No problem! You might not be able to pay a travel agency, hop on a plane, or go on an adventure right now. But with Linguado you have the world in the palm of your hand. Or if you’re already on the road, use the Map to link with locals to discover your next destination.`,
        paragraphThreeTitle: `CULTURAL EXCHANGE`,
        paragraphThreeText: `In some countries it’s a handshake, in others it’s a bow. Whether its forks or chopsticks, grasping the culture and understanding social norms, customs, manners, and food are equally important as studying the language.`,
      },
      features: {
        featureOne: "8 languages",
        featureTwo: "21009 users",
        featureThree: "158 lessons",
        text: `Would you like to find
          out more?`,
        link: "https://polyglot-edu.eu/en"  
      },
    },
    otherProject: {
      hero: { headline: "Stay tuned!" },
      about: {
        title: "Other Projects",
        text: `We work so hard, in part, because we feel that we are part of something bigger
        than ourselves. We believe that the mission of the organization aligns to our own
        values and goals, and that our work is an important part of achieving that larger
        institutional mission.`,
      },
      progress: {
        text: `We are working on
        something awesome.
        Stay tuned!`,
      },
    },
    about: {
      hero: { headline: "About us" },
      about: {
        textUp: `We seek to provide a professional, safe, and enjoyable work environment that supports job enthusiasm, positive relationships, and effective teamwork. `,
        textDown: `We work to provide clear communication of direction and goals and to motivate our team to achieve these goals.
        `,
      },
      company: {
          title: `We value professionalism`,
        text: `We insist upon professionalism in our work, our actions, our appearance, and our communications.  We believe in taking pride in our image and furthering this image through high integrity and professional behavior.`,
        title1: `We value technology and innovation `,
        paragraphOne: `We seek to be recognized as a technological leader in our industry, and thus are committed to sound investments in appropriate products, systems, and equipment in our quest for excellence.`,
        paragraphTwo: `We strive to create an attitude of creativity, innovation, and inspiration in each employee through the encouragement of prudent risk-taking. `,
        title2: `We value growth and profitability`,
        paragraphThree: `We seek to provide long-term sustainable growth for our company.  We strive to find balance and prudence in the investments and expenditures to spur our growth.`,
        paragraphFour: `We recognize that sustained profitability is necessary for the survival of our organization.`,
      },
    },
    career: {
      hero: { headline: "Career" },
      description: {
        text: `At EEA, we make learning a language easier by combining AI-powered courses and instant feedback from
        our global community. If you love languages, want to work with smart, creative, energetic people and
        possess the initiative, confidence and good judgement to make independent decisions every day, then
        you’ll love working with us!`,
      },
      company: {
        title: "Our company values",
        data: [
          {
            headline: "Trust",
            text: `We give everyone responsibility from day one. We always deliver on
                our promises, and treat each other with respect.`,
          },
          {
            headline: "Effectiveness",
            text: `For us, empowering our customers means solving the right problems –
                then going above and beyond to get stuff done.`,
          },
          {
            headline: "Ambition",
            text: `We always think big and try to go the extra mile – and we pride ourselves
                on leaving a legacy.`,
          },
          {
            headline: "Curiosity",
            text: `We like to question the status quo. We test, learn and share so we
                can better ourselves and our work every day.`,
          },
        ],
      },
      job: {
          title: "Open positions",
          paragraphOneTitle: "TECHNOLOGY",
          paragraphOneLiOne: "IT Specialist",
          paragraphOneLiTwo: "QA Engineer",
          paragraphTwoTitle: "DESIGN",
          paragraphTwoLiOne: "Product Designer",
          paragraphThreeTitle: "BUSINESS",
          paragraphThreeLiOne: "Accountant",
          paragraphThreeLiTwo: "Sales Operations Manager",
          textUp: `Get in touch! We’re always delighted to say hello to a fresh face.`,
          textDown: `We reserve the right to contact only selected individuals.`,
      },
      section: {
          title: "Contact us",
          name: "Name",
          lastName: "Last name",
          email: "e-mail adress",
          message: "Message content",
          submit: "Send",
      },
    },
  };
  